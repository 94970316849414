.ordenTransferBox {
    margin-top: 100px !important;
    width: 993px !important;
    height: 710px !important;
    top: 242px !important;
    left: 224px !important;
    padding: 60px 0px 0px 0px !important;
    gap: 58px !important;
    border-radius: 13px 0px 0px 0px !important;
    
    background-color: #FFFFFF !important;

}

.ordenTransferTitle {
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 34px !important;
    font-weight: 600 !important;
    line-height: 48px !important;
    text-align: center !important;
    color: #212C40 !important;
    margin-top: -80px !important;
}


.ordenTransferSubtitle {
    height: 23px !important;
    gap: 0px !important;
    
    font-family: 'Roboto' !important;
    font-size: 26px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    text-align: center !important;


}

.ordenTransferFileUpload {
    width: 90% !important;
    border-radius: 12px !important;
    font-family: 'Roboto' !important;
    margin-left: 50px !important;
    margin-top: 50px !important;
}

.ordenTransferInfo {
    gap: 0px !important;
    font-family: 'Roboto' !important;
    font-size: 14px !important;
    margin-left: 60px !important;
}




/* Escritorio grande */
@media only screen and (min-width: 1800px) {}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (min-width: 965px) and (max-width: 984px) {}

@media only screen and (min-width: 768px) and (max-width: 964px) {}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenTransferBox {
        width: 385px !important;
        height: 1000px !important;
        margin-top: 0px !important;

    }

    .ordenTransferTitle {
        font-size: 24px !important;
        font-weight: 800 !important;
        line-height: 48px !important;

    }

    .ordenApprovedButtonCheck {
        width: 90px !important;
        height: 90px !important;
        margin-left: 140px !important;
    }

    .ordenTransferSubtitle {
        font-size: 22px !important;
        margin-top: 10px !important;
    }

    .ordenTransferFileUpload {
        width: 100% !important;
        height: 10% !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
    .textUpload{
        height: 10% !important;
    }
    .ordenTransferInfo {
        gap: 0px !important;
        font-family: 'Roboto' !important;
        font-size: 14px !important;
        margin-left: 10px !important;
    }
}