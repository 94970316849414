.ordenDetailHeader {
    width: 102% !important;
    height: 110px !important;
    margin-left: -1% !important;
    top: 2px !important;
    padding: 16px !important;
    padding-bottom: 120px !important;
    gap: 20px !important;
    background-color: #19366C !important;
    text-align: center !important;
}

.ordenDetailTitle {

    /* width: 181px !important; */
    height: 34px !important;
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 48px !important;
    line-height: 48px !important;
    margin-left: 0px !important;
    margin-top: 12px !important;
    color: #FFFFFF !important;
}

.addParticipant {
    width: 250px !important;
    height: 43px !important;
    border-color: #19366C !important;
    color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
    margin-top: 10px !important;
    margin-left: 0px !important;
}

.ordenDetialBody {
    width: 1122px !important;
    height: 389px !important;
    border-radius: 13px !important;
    gap: 8px !important;
    margin-top: -50px !important;

}

.ordenDetailCard {
    width: 1122px !important;
    margin-left: 400px !important;
    margin-top: 50px !important;
    border: none !important;
    box-shadow: none !important;

}

.rowDetail {
    margin-bottom: -100px;
}

.ordenDetailCheck {
    width: 20px !important;
    height: 20px !important;
    padding: 10px, 8px, 10px, 8px !important;
    gap: 8px !important;
    background-color: #F8F9FC !important;
}

.ordenDetailTextNumber {
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #666666 !important;
    margin-left: 10px !important;
}

.ordenDetailTextPriceWithDiscount {
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #666666 !important;
    margin-top: 0px !important;
    margin-left: 28px !important;
}

.ordenDetailTextAmount {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    text-decoration: line-through;
    margin-top: 0px !important;
    margin-left: 0px !important;
}

.ordenDetailShowModalFont {
    font-family: 'Roboto' !important;
}
.p-dialog-content{
    background-color: #CED4DA !important;
}

.ordenDetailWhosComming {
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px !important;
    color: #19366C !important;
    margin-left: 0px !important;
}

.meButton {
    width: 137px !important;
    height: 43px !important;
    border-color: #19366C !important;
    color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
    margin-left: 30px !important;

}

.delegateButton {
    width: 137px !important;
    height: 43px !important;
    border-color: #19366C !important;
    color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
    margin-left: 40px !important;
}

.modifyButton {
    width: 137px !important;
    height: 43px !important;
    color: #D45A48 !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 28px !important;
}

.ordenDetailResumeBox {
    width: 1122px !important;
    height: 100px !important;
    margin-left: 400px !important;
    margin-top: 102px !important;
    justify-content: space-between !important;
    padding: 11px, 4px, 11px, 4px !important;
    background-color: #D7DDEA !important;
}

.ordenDetailMessage {
    width: 425px !important;
    height: 35px !important;
    gap: 8px !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    margin-top: -20px !important;
    text-align: justify !important;

}

.ordenDetailTotal {
    width: 425px !important;
    height: 35px !important;
    gap: 8px !important;
    font-family: 'Roboto' !important;
    font-weight: 600;
    font-size: 28px !important;
    line-height: 28px !important;
    color: #19366C !important;
    text-align: right !important;
}

.ordenDetailCheckoutButton {
    height: 43px !important;
    border-color: #19366C !important;
    background-color: #19366C !important;
    color: #ffffff !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
    margin-top: -10px !important;
}

/* Escritorio grande */
@media only screen and (min-width: 1800px) {
    .ordenDetialBody {
        width: 100% !important;
        margin-top: 20px !important;



    }

    .ordenDetailResumeBox {
        width: 97% !important;
        margin-left: 29px !important;
    }
}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ordenDetailHeader {
        width: 100% !important;
        margin-left: 1px !important;
    }

    .ordenDetialBody {
        width: 100% !important;
        margin-top: 20px !important;
       
    }

    .ordenDetailCard {
        width: 96% !important;
        margin-left: 20px !important;
       

    }

    .ordenDetailResumeBox {
        width: 1119px !important;
        margin-left: 0px !important;
    }

    .ordenDetailMessage {
        width: 280px !important;
    }

    .ordenDetailTotal {
        width: 290px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1799px) {
    .ordenDetialBody {
        padding-left: 11% !important;
    }
    .ordenDetailCard {
       
        margin-left: 0px !important;
        

    }
    .ordenDetailResumeBox {
        /* background-color: red !important; */
        width: 960px !important;
        margin-left: 160px !important;
    }
}

@media only screen and (min-width: 965px) and (max-width: 984px) {
    .ordenDetialBody {
        width: 960px !important;
        margin-top: 0px !important;

    }

    .ordenDetailCard {
        width: 960px !important;
        margin-left: 1px !important;
        

    }

    .ordenDetailResumeBox {
        width: 960px !important;
        margin-left: 1px !important;
    }

    .ordenDetailMessage {
        width: 280px !important;
    }

    .ordenDetailTotal {
        width: 290px !important;
        font-size: 20px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 964px) {
    .ordenDetialBody {
        width: 800px !important;
        margin-top: 0px !important;
        margin-left: 0px !important;

    }

    .ordenDetailCard {
        width: 800px !important;
        margin-left: 0px !important;

    }

    .meButton {
        width: 125px !important;
        height: 43px !important;
        border-color: #19366C !important;
        color: #19366C !important;
        font-family: 'Roboto' !important;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 18.75px !important;

    }

    .ordenDetailResumeBox {
        width: 800px !important;
        height: 115px !important;
        margin-left: 0px !important;
    }


    .ordenDetailMessage {
        width: 250px !important;
    }

    .ordenDetailTotal {
        width: 295px !important;
        font-size: 20px !important;
    }
}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
@media only screen and (max-width: 430px) {
    .ordenDetailHeader {
        width: 102% !important;
        height: 80px !important;
        text-align: center !important;

    }

    .ordenDetailTitle {
        margin-top: 4px !important;
    }

    .ordenDetialBody {
        width: 380px !important;
        margin-top: 20px !important;

    }

    .ordenDetailCard {
        width: 380px !important;
        margin-left: 1px !important;
        margin-top: 0px !important;
    }

    .ordenDetailTextNumber {
        font-size: 18px !important;
        font-weight: 600 !important;
        width: 20px !important;
        margin-left: 50px !important;

    }

    .ordenDetailTextPriceWithDiscount {
        font-size: 18px !important;
        font-weight: 600 !important;
        width: 20px !important;
        /* margin-top: 0px !important;
        margin-left: 100px !important; */
        margin: auto !important;
    }

    .ordenDetailWhosComming {
        font-weight: 400;
        font-size: 18px !important;
        /* margin-left: 100px !important; */
        margin: auto !important;
    }

    .meButton {
        margin-left: 0px !important;
        width: 100% !important;
    }

    .delegateButton {
        margin-left: 0px !important;
        width: 100% !important;
    }
    .modifyButton {
        margin-left: 0px !important;
        width: 100% !important;
    }

    .mobile {
        margin-top: -60px !important;
    }

    .ordenDetailResumeBox {
        width: 380px !important;
        height: 185px !important;
        margin-left: 1px !important;
        align-content: center !important;

    }

    .ordenDetailMessage {
        width: 100% !important;

    }

    .ordenDetailTotal {
        width: 100% !important;
        margin-top: 10px !important;
        text-align: center !important;
    }

    .ordenDetailCheckoutButton {
        width: 100% !important;
    }
    .completParticipant {
        width: 100% !important;
        height: 43px !important;
        border-color: #19366C !important;
        color: #19366C !important;
        font-family: 'Roboto' !important;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 18.75px !important;
        margin-top: 10px !important;
        margin-left: 0px !important;
    }
    .ordenDetailCheckoutButton {
        height: 43px !important;
        border-color: #19366C !important;
        background-color: #19366C !important;
        color: #ffffff !important;
        font-family: 'Roboto' !important;
        font-weight: 400;
        font-size: 16px !important;
        line-height: 18.75px !important;
        margin-top: -10px !important;
    }
}

.p-dialog-header-icons {
    color: black !important;
    background-color: #19366C !important;
    border-radius: 16px !important;
}