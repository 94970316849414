.forgotPasswordBody {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 98vh !important;
}

.forgotPasswordCard {
    width: 441px !important;
    height: 500px !important;
    border-radius: 13px !important;
    padding: 40px !important;
    padding-left: 10px !important;
    gap: 32px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 6px 0px #19213D24 !important;
}

.forgotPasswordImage {
    object-fit: fill !important;
    width: 380px !important;
    height: 156px !important;
    margin-top: -30px !important;
    margin-bottom: 40px !important;
}

.forgotPasswordTitle {
    width: 366px !important;
    height: 61px !important;
    gap: 24px !important;
    margin-bottom: 10px !important;

}

.forgotPasswordTitleText {
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    font-size: 26px !important;
    line-height: 32px !important;
    width: 100% !important;
    height: 23px !important;
    gap: 0px !important;
    color: #212C40 !important;
    margin-left: 10px !important;

}


.forgotPasswordEmailTextBox {
    width: 100% !important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid #FFFFFF !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    box-shadow: 0px 1px 4px #19213D14 !important;
    margin-left: -10px !important;

}

.forgotPasswordButton {
    width: 180px !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;
}

.forgotPasswordButtonBack {
    width: 180px !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    background-color: #2755A9 !important;
}

@media only screen and (max-width: 430px) {
    .forgotPasswordBody {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        height: 98vh !important;
    }

    .forgotPasswordCard {
        width: 441px !important;
        height: 520px !important;
        border-radius: 13px !important;
        padding: 40px !important;
        padding-left: 30px !important;
        gap: 32px !important;
        background-color: #FFFFFF !important;
        box-shadow: 0px 2px 6px 0px #19213D24 !important;
    }

    .forgotPasswordImage {
        object-fit: fill !important;
        width: 300px !important;
        height: 156px !important;
        margin-left: 10px !important;
        margin-top: -30px !important;
        margin-bottom: 40px !important;
    }

    .forgotPasswordTitle {
        width: 366px !important;
        height: 61px !important;
        gap: 24px;
        
        margin-bottom: 10px !important;

    }

    .forgotPasswordTitleText {
        font-family: 'Roboto' !important;
        font-weight: 600 !important;
        font-size: 26px !important;
        line-height: 32px !important;
        width: 181px !important;
        height: 23px !important;
        gap: 0px !important;
        
        color: #212C40 !important;
        margin-left: 10px !important;

    }


    .forgotPasswordEmailTextBox {
        width: 340px !important;
        height: 46px !important;
        border-radius: 6px !important;
        border: 1px solid #FFFFFF !important;
        padding: 18px, 16px, 18px, 16px !important;
        gap: 10px !important;
        box-shadow: 0px 1px 4px #19213D14 !important;
        margin-left: 0px !important;

    }

    .forgotPasswordButton {
        width: 100% !important;
        height: 43px !important;
        border-radius: 4px !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #19366C !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        color: #FFFFFF !important;
    }

    .forgotPasswordButtonBack {
        width: 100% !important;
        height: 43px !important;
        border-radius: 4px !important;
        padding: 16px, 32px, 16px, 32px !important;
        gap: 10px !important;
        background-color: #19366C !important;
        font-family: 'Roboto' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 18.75px !important;
        background-color: #2755A9 !important;
    }

}