.loginBody {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 98vh !important;

}

.loginCard {
    width: 441px !important;
    height: 590px !important;
    border-radius: 13px !important;
    padding: 40px !important;
    padding-left: 20px !important;
    gap: 32px !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 2px 6px 0px #19213D24 !important;
    
}

.loginImage {
    object-fit: contain !important;
    width: 380px !important;
    height: 156px !important;
    margin-top: -30px !important;
    margin-bottom: 40px !important;
}

.loginTitle {
    width: 366px!important;
    height: 61px!important;
    gap: 24px!important;
    margin-top: -10px!important;
}

.loginTitleText {
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    font-size: 32px !important;
    line-height: 32px !important;
    width: 181px !important;
    height: 23px!important;
    color: #212C40!important;
}

.loginTitleNewUser {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    width: 366px!important;
    height: 14px!important;

}

.loginEmailTextBox {
    width: 366px !important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid #FFFFFF !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    box-shadow: 0px 1px 4px 0px #19213D14 !important;
    margin-top: -10px !important;
    margin-left: 0px !important;
    font-family: 'Roboto' !important;

}

.passwordTextBox {
    width: 366px !important;
    height: 46px !important;
    border-radius: 6px !important;
    border: 1px solid #FFFFFF !important;
    padding: 18px, 16px, 18px, 16px !important;
    gap: 10px !important;
    box-shadow: 0px 1px 4px #19213D14 !important;
    margin-bottom: 0px !important;
    margin-top: -10px !important;
    font-family: 'Roboto' !important;
}

.forgotPassword {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #212C40 !important;
}

.loginButton {
    width: 366px !important;
    height: 43px !important;
    border-radius: 4px !important;
    padding: 16px, 32px, 16px, 32px !important;
    gap: 10px !important;
    background-color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18.75px !important;
    color: #FFFFFF !important;
    margin-top: 0px !important;
}
