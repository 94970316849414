.p-dialog-header {
    background-color: #FFFFFF !important;
    color: #000000 !important;
}

.p-dialog-header-close-icon.pi.pi-times {
    color: #000000 !important;
}

.DialogModalSize {
    width: 40% !important;
    height: 100% !important;
}

.DialogModalSearchInput {
    width: 470px !important;
    height: 40px !important;
    border-radius: 6px !important;
    border: 1px solid #F8FAFF !important;
    background-color: #E1E4ED !important;
    color: #6D758F !important;
    padding-left: 16px !important;
    margin-top: 0px !important;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 32px !important;
}

.DialogModalSearchButton {
    background-color: #19366C !important;
    border-color: #19366C !important;
    color: #FFFFFF !important;
}

.DialogModalClearButton {
    background-color: #ffffff !important;
    border-color: #19366C !important;
    color: #19366C !important;
}

.DialogModalCancelButton {
    width: 137px !important;
    height: 43px !important;
    border-color: #19366C !important;
    color: #19366C !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
}

.DialogModalSaveButton {
    width: 137px !important;
    height: 43px !important;
    border-color: #19366C !important;
    background-color: #19366C !important;
    color: #ffffff !important;
    font-family: 'Roboto' !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 18.75px !important;
}

.userFormDetailCard {
    width: 100% !important;
    height: 1000px !important;
    gap: 32px !important;
    
    border: none !important;
    box-shadow: none !important;

}

.userFormDetailLabel {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;

}

.userFormDetailInput {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    border: none !important;
    width: 100% !important;
    border-bottom: solid 2px #19213D14 !important;
    border-left: solid 2px #19213D14 !important;
    border-right: solid 2px #19213D14 !important;
    border-radius: 8px !important;

}

.userFormDetailMessage {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #666666 !important;
    text-align: justify !important;
}

.userFormDetailDropdown {
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #D0D8E7 !important;
    border: none !important;
    width: 100% !important;
    border-bottom: solid 2px #19213D14 !important;
    border-left: solid 2px #19213D14 !important;
    border-right: solid 2px #19213D14 !important;
    border-radius: 8px !important;

}

.userFormDetailTitle {
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #212C40 !important;
}

.userFormDetailCheckbox {
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    background-color: #DDE3ED !important;
}

/* Escritorio grande */
@media only screen and (min-width: 1800px) {
    
}

/* Escritorio medio */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .DialogModalSize {
        width: 70% !important;
        height: 100% !important;
    }
}

@media only screen and (min-width: 965px) and (max-width: 984px) {
    .DialogModalSize {
        width: 60% !important;
        height: 100% !important;
        
    }
}

@media only screen and (min-width: 768px) and (max-width: 964px) {
    .DialogModalSize {
        width: 60% !important;
        height: 100% !important;
    }
}

/* Teléfonos */
@media only screen and (min-width: 521px) and (max-width: 767px) {}

/* Teléfonos */
@media only screen and (max-width: 430px) {
    .DialogModalSize {
        width: 100% !important;
        height: 100% !important;
    }
}